import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Drawer, IconButton, SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material';
import { routesWithBackBtnOnPage } from 'assets/routes';
import { prevPageUrlStorage, sidebarStorage } from 'helpers/storageFunctions';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RolePermissions } from '../../../helpers/RolePermissions';
import { getCurrentUser } from '../../../redux/selectors/auth';
import ProfilesIcons from '../../../shared/profiles-icons';

import { ReactComponent as OmiteamLogo } from '../../../svg/authorizationSection/omi-team.svg';
import { getIcon } from './icons';
import SidebarContainer from './sidebar-container';
import SidebarLink from './sidebar-link';

import { RoutesConstants } from 'constants/routesConstants';
import { useStyles } from './styles';
import { getSelectedTeam } from '../../../redux/selectors/teams';

type Links = {
  candidates: React.ReactElement;
  vacancies: React.ReactElement;
  clients: React.ReactElement;
  reminders: React.ReactElement;
};

type SidebarProps = {
  children: React.ReactNode;
};

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const theme = useTheme();
  const history = useHistory();
  const isMobileSidebar = useMediaQuery(theme.breakpoints.down('mobile'));

  const drawerRef = useRef<HTMLDivElement | null>(null);
  const drawerPaperRef = useRef<HTMLDivElement | null>(null);

  const user = useSelector(getCurrentUser);
  const activeTeam = useSelector(getSelectedTeam);

  const { getSidebarState, setSidebarState } = sidebarStorage();

  const [open, setOpen] = useState<boolean>(getSidebarState());
  const [isActive, setIsActive] = useState<boolean>(false);

  const mobile = isMobileSidebar && isActive;

  const { classes } = useStyles({ open, mobile, isActive });

  useEffect(() => {
    setSidebarState(open);
  }, [open]);

  const onProfilesClickHandler = () => {
    const pathname = window.location.pathname;
    const isRouteWithBackBtnOnPage = routesWithBackBtnOnPage.includes(pathname);

    if (!isRouteWithBackBtnOnPage) {
      const prevPathes = prevPageUrlStorage().getPrevPageUrl();
      prevPageUrlStorage().setPrevPageUrl([...prevPathes, pathname]);
    }

    history.push('/profiles-navigation');
    setIsActive(false);
  };

  const changeSidebarDrawerVisibility = (openOnClick?: boolean, isMobile?: boolean) => {
    if ((typeof isMobile === 'boolean' ? !isMobile : true) && !isMobileSidebar) {
      return;
    }

    const isContainVisibilityClass = drawerRef.current?.style.visibility === 'visible';

    if ((!isContainVisibilityClass || !drawerRef.current?.style.visibility) && openOnClick) {
      drawerRef.current!.style.visibility = 'visible';
      drawerPaperRef.current!.style.transform = 'none';
    } else {
      if (drawerPaperRef.current) {
        drawerPaperRef.current.style.transform = 'translateY(-812px)';
      }
      if (drawerRef.current) {
        drawerRef.current.style.visibility = 'hidden';
        drawerRef.current.style.transition = 'visibility 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms';
      }
    }
  };

  const closeDesktopSidebar = () => {
    setOpen((open) => !open);
  };

  const links: Links = {
    candidates: (
      <SidebarLink
        key={1}
        route='candidates/list'
        label={isMobileSidebar ? 'candidatesSection' : open ? 'candidatesSection' : ''}
        icon={getIcon('candidates')}
        toggleDrawer={setIsActive}
        changeSidebarVisibility={(isMobile: boolean) => changeSidebarDrawerVisibility(false, isMobile)}
        isSidebarOpen={open}
      />
    ),
    vacancies: (
      <SidebarLink
        key={2}
        route='vacancies'
        vacancyRoute='vacancy'
        label={isMobileSidebar ? 'vacanciesSection' : open ? 'vacanciesSection' : ''}
        icon={getIcon('vacancies')}
        toggleDrawer={setIsActive}
        changeSidebarVisibility={(isMobile: boolean) => changeSidebarDrawerVisibility(false, isMobile)}
        isSidebarOpen={open}
      />
    ),
    clients: (
      <SidebarLink
        key={3}
        route='clients'
        label={isMobileSidebar ? 'clientsSection' : open ? 'clientsSection' : ''}
        icon={getIcon('clients')}
        toggleDrawer={setIsActive}
        changeSidebarVisibility={(isMobile: boolean) => changeSidebarDrawerVisibility(false, isMobile)}
        isSidebarOpen={open}
      />
    ),
    reminders: (
      <SidebarLink
        key={4}
        route='reminders'
        label={isMobileSidebar ? 'remindersSection' : open ? 'remindersSection' : ''}
        icon={getIcon('reminders')}
        toggleDrawer={setIsActive}
        changeSidebarVisibility={(isMobile: boolean) => changeSidebarDrawerVisibility(false, isMobile)}
        isSidebarOpen={open}
      />
    ),
  };

  const routesWithAccess = useMemo(() => {
    if (user) {
      const currentUserRoleInTeam = activeTeam?.employees.find((employee) => employee?._id?._id === user?._id)?.role;
      const rotesListWithAccess = RolePermissions.canAccessSections(user?.role, currentUserRoleInTeam).map(
        (route: string) => links[route as keyof Links],
      );
      if (rotesListWithAccess) {
        return (
          <div>
            <div className='link-menu-HR'>{rotesListWithAccess}</div>
          </div>
        );
      }
    }
    return <></>;
  }, [user, activeTeam, open]);

  const onBurgerMenuButtonClick = () => {
    changeSidebarDrawerVisibility(true);
    setIsActive(!isActive);
  };

  const onLogoClick = () => {
    changeSidebarDrawerVisibility(false);
    setIsActive(false);
    prevPageUrlStorage().setPrevPageUrl([]);
  };

  const onSidebarClose = () => {
    changeSidebarDrawerVisibility(false);
    setIsActive(false);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.mobileSidebar}>
        <div className={classes.mobileNavbar}>
          <div className={classes.leftSide}>
            <IconButton onClick={onBurgerMenuButtonClick} className={classes.menuBtn}>
              {isActive ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
            </IconButton>
            <Link to={RoutesConstants.HOMEPAGE} onClick={onLogoClick}>
              <OmiteamLogo className={classes.logo} style={{ marginLeft: '12px' }} />
            </Link>
          </div>
          <ProfilesIcons onClick={onProfilesClickHandler} changeSidebarVisibility={changeSidebarDrawerVisibility} />
        </div>

        <SwipeableDrawer
          anchor='top'
          open={isActive}
          onClose={onSidebarClose}
          onOpen={() => setIsActive(true)}
          hideBackdrop={true}
          transitionDuration={{ enter: 500, exit: 500 }}
          PaperProps={{
            ref: drawerPaperRef,
            style: {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
          }}
          ModalProps={{
            ref: drawerRef,
          }}
        >
          <div className={classes.mobileSidebarWrap}>
            <SidebarContainer>{routesWithAccess}</SidebarContainer>
          </div>
        </SwipeableDrawer>
      </div>
      <div className={classes.desktopSidebar}>
        <IconButton className={classes.toggleButton} onClick={closeDesktopSidebar}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Drawer
          open={open}
          anchor='left'
          variant={'persistent'}
          PaperProps={{
            sx: {
              width: open ? 304 : 113,
              transition: 'width 0.5s ease !important',
              transform: 'none !important',
              visibility: 'visible !important',
              position: 'relative',
              overflow: 'hidden',
            },
          }}
        >
          <SidebarContainer isSidebarOpen={open} isMobileSidebar={isMobileSidebar}>
            {routesWithAccess}
          </SidebarContainer>
        </Drawer>
      </div>
      {children}
    </div>
  );
};

export default Sidebar;
