import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { initialValuesType } from '../../containers/signup/config';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { IUserInitialValues } from '../../types/api/users';
import { ApiService } from '../middleware/api';

export const SIGN_IN_SUCCESS = createAction('auth/SIGN_IN.SUCCESS');
export const SIGN_IN_FAILURE = createAction('auth/SIGN_IN.FAILURE');
export const signIn =
  (userData: initialValuesType, onFailure: (error: string) => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: 'auth/login',
        query: {
          user: {
            ...userData,
          },
        },
      });

      dispatch(SIGN_IN_SUCCESS(data));
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      dispatch(SIGN_IN_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const SIGN_UP_SUCCESS = createAction('auth/SIGN_UP.SUCCESS');
export const SIGN_UP_FAILURE = createAction('auth/SIGN_UP.FAILURE');
export const signUp =
  (userData: initialValuesType, token: string, onFailure: (error: string) => void) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: token ? `auth/signup/${token}` : 'auth/signup',
        query: {
          user: {
            ...userData,
          },
        },
      });
      dispatch(SIGN_UP_SUCCESS(data));
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      dispatch(SIGN_UP_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const GET_CURRENT_USER_SUCCESS = createAction('auth/GET_CURRENT_USER.SUCCESS');
export const fetchCurrentUser = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await ApiService.apiCall({
      method: 'GET',
      endpoint: `users/current`,
    });
    dispatch(GET_CURRENT_USER_SUCCESS(data));
  } catch (err) {}
};

export const UPDATE_CURRENT_USER_SUCCESS = createAction('auth/UPDATE_CURRENT_USER.SUCCESS');
export const UPDATE_CURRENT_USER_FAILURE = createAction('auth/UPDATE_CURRENT_USER.FAILURE');
export const updateCurrentUser =
  (id: string, userData: IUserInitialValues, onSuccess: () => void, onFailure: (error: string) => void) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `users/${id}`,
        query: userData,
      });
      onSuccess();
      setTimeout(() => {
        dispatch(UPDATE_CURRENT_USER_SUCCESS(data));
      }, 1000);
    } catch (err) {
      const errorMessage = getErrorMessage(err);

      dispatch(UPDATE_CURRENT_USER_FAILURE(errorMessage));
      onFailure(errorMessage);
    }
  };

export const LOG_OUT = createAction('auth/LOG_OUT');
export const logOut = () => async (dispatch: AppDispatch) => {
  try {
    localStorage.removeItem('team');
    localStorage.setItem('lastVisited', window.location.pathname);
    dispatch(LOG_OUT());
  } catch (err) {}
};

export const CLEAR_AUTH_ERRORS = createAction('auth/CLEAR_AUTH_ERRORS');
export const clearAuthErrors = () => (dispatch: AppDispatch) => {
  dispatch(CLEAR_AUTH_ERRORS());
};
