import { getTeamById } from '../../../actions/teams';
import { store } from '../../../index';

export function* onDeleteTeam() {
  const { dispatch } = store;

  dispatch(getTeamById({}));

  yield true;
}
