import { store } from '../../../index';
import { PayloadAction } from '@reduxjs/toolkit';
import { ITeams } from '../../../../types/api/teams';
import { getTeamById } from '../../../actions/teams';

export function* onChangeEmployeeStatus(action: PayloadAction<ITeams>) {
  const { dispatch } = store;

  dispatch(getTeamById({}));

  yield true;
}
