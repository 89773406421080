import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    avatar: {
      width: '40px',
      height: '40px',
      fontSize: '1rem',
      border: `1px solid ${theme.palette.primary.contrastText}`,
      backgroundColor: 'rgba(69, 43, 228, 0.6)',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      padding: '16px',
      borderLeft: '1px solid #DADADA',
      alignSelf: 'stretch',

      [theme.breakpoints.down('mobile')]: {
        borderLeft: 'none',
      },
    },
    info: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: '8px',
    },
    teamName: {
      fontWeight: 600,
      marginLeft: '16px',
    },
    divider: {
      marginTop: '8px',
    },
    navLink: {
      marginTop: '4px',
      border: `8px solid transparent`,
      minWidth: '235px',
      cursor: 'pointer',
      transition: 'all 150ms linear',

      '&:hover': {
        backgroundColor: 'rgba(69, 43, 228, 0.15)',
        transition: 'all 150ms linear',
      },
    },

    activeNavLink: {
      marginTop: '16px',
      backgroundColor: theme.palette.primary.main,
      opacity: 0.1,
    },
    settingsMenuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '1.5rem',
      cursor: 'pointer',
    },
    settingsArrow: {
      transform: 'rotate(-90deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    activeSettingsArrow: {
      transform: 'rotate(90deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    logoutMenuItem: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 150ms linear',

      '&:hover': {
        backgroundColor: 'rgba(253, 79, 79, 0.15)',
        transition: 'all 150ms linear',
      },
    },
    logoutText: {
      marginLeft: '8px',
    },
    disabledNavLink: {
      marginTop: '4px',
      border: `8px solid transparent`,
      minWidth: '235px',
      opacity: 0.5,

      [theme.breakpoints.down('mobile')]: {
        display: 'none',
      },
    },
    disabledLink: {
      cursor: 'default',
      pointerEvents: 'none',
    },
  };
});

export default useStyles;
