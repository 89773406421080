import { put } from 'redux-saga/effects';
import { tokenStorage, selectedTeamStorage } from '../../../../helpers/storageFunctions';

import { CLEAR_AUTH_ERRORS } from '../../../actions/auth';
import { PayloadAction } from '@reduxjs/toolkit';
import { CurrentUser } from '../../../../types/api/users';
import { commonRoutes, publicRoutes } from '../../../../assets/routes';
import { matchPath } from 'react-router-dom';
import { RoutesConstants } from 'constants/routesConstants';

type Payload = {
  accessToken: string;
  refreshToken: string;
  user: CurrentUser;
};

function* onSignIn({ payload }: PayloadAction<Payload>) {
  yield put({ type: CLEAR_AUTH_ERRORS });

  const accessToken = payload?.accessToken;
  const refreshToken = payload?.refreshToken;
  const userTeams = payload?.user?.teams;

  const goToPage = (pagePath: string, hasTeams: boolean) => {
    const lastVisitedPage = localStorage.getItem('lastVisited');

    if (lastVisitedPage && !publicRoutes.includes(lastVisitedPage) && hasTeams) {
      window.location.replace(lastVisitedPage);
    } else if (lastVisitedPage && !hasTeams) {
      window.location.replace('/team/create');
    } else {
      window.location.replace(pagePath);
    }
  };

  tokenStorage().setAccessToken(accessToken);
  tokenStorage().setRefreshToken(refreshToken);

  if (userTeams.length) {
    selectedTeamStorage().setSelectedTeam(userTeams[0]._id);
    const isPathNameCorrect = commonRoutes.find((route) =>
      matchPath(window.location.pathname, { path: route, exact: true }),
    );

    if (isPathNameCorrect) {
      goToPage(window.location.pathname, true);
    } else {
      goToPage(RoutesConstants.HOMEPAGE, true);
    }
  } else {
    selectedTeamStorage().setSelectedTeam(null);
    goToPage('/team/create', false);
  }

  yield true;
}

export default onSignIn;
