import { store } from '../../../index';
import { clearAuthErrors } from '../../../actions/auth';
import { tokenStorage, selectedTeamStorage } from '../../../../helpers/storageFunctions';
import { PayloadAction } from '@reduxjs/toolkit';
import { CurrentUser } from 'types/api/users';

type Payload = {
  accessToken: string;
  refreshToken: string;
  user: CurrentUser;
};

function* onSignUp({ payload }: PayloadAction<Payload>) {
  const { dispatch } = store;
  dispatch(clearAuthErrors());

  const accessToken = payload?.accessToken;
  const refreshToken = payload?.refreshToken;
  const userTeams = payload?.user?.teams;

  tokenStorage().setAccessToken(accessToken);
  tokenStorage().setRefreshToken(refreshToken);

  if (userTeams.length) {
    selectedTeamStorage().setSelectedTeam(userTeams[0]._id);
    window.location.replace('/team/profile');
  } else {
    selectedTeamStorage().setSelectedTeam(null);
    window.location.replace('/team/create');
  }

  yield true;
}

export default onSignUp;
