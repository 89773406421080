import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { LOADING } from '../../assets/loading';

import { IUser } from '../../types/api/users';
import {
  CLEAR_SELECTED_USER,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_PENDING,
  GET_ALL_USERS_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_PENDING,
  GET_USER_BY_ID_SUCCESS,
} from '../actions/users';

type InitialState = {
  users: IUser[];
  loading: string | null;
  selectedUser: IUser | null;
};

const initialState: InitialState = {
  users: [],
  loading: null,
  selectedUser: null,
};

const handlers = {
  [GET_ALL_USERS_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IUser[]>) => {
    return {
      ...prevState,
      users: payload,
      loading: LOADING.SUCCESS,
    };
  },
  [GET_ALL_USERS_PENDING.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.PENDING,
    };
  },
  [GET_ALL_USERS_FAILURE.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.FAILURE,
    };
  },
  [GET_USER_BY_ID_SUCCESS.type]: (prevState: InitialState, { payload }: PayloadAction<IUser>) => {
    return {
      ...prevState,
      selectedUser: payload,
      loading: LOADING.SUCCESS,
    };
  },
  [GET_USER_BY_ID_PENDING.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.PENDING,
    };
  },
  [GET_USER_BY_ID_FAILURE.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      loading: LOADING.FAILURE,
    };
  },
  [CLEAR_SELECTED_USER.type]: (prevState: InitialState) => {
    return {
      ...prevState,
      selectedUser: null,
    };
  },
};

export default createReducer(initialState, handlers);
