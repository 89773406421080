import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { ApiService } from '../middleware/api';
import { DicisionMakerData } from 'components/modal/addDecisionMakerModal/config';

export const GET_TEAM_SUCCESS = createAction('teams/GET_TEAM.SUCCESS');
export const GET_TEAM_PENDING = createAction('teams/GET_TEAM.PENDING');
export const GET_TEAM_FAILURE = createAction('teams/GET_TEAM.FAILURE');
type GetTeamActionType = {
  onFailure?: (errror: string) => void;
};
export const getTeamById =
  ({ onFailure }: GetTeamActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: 'teams',
      });

      dispatch(GET_TEAM_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      dispatch(GET_TEAM_FAILURE());
      onFailure?.(errorMessage);
    }
  };

export const CREATE_TEAM_SUCCESS = createAction('users/CREATE_TEAM.SUCCESS');
type CreateTeamActionType = {
  teamData: {
    name: string;
    membersEmails?: string[];
    source: string | null;
    teamSize: string | null;
  };
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const createTeam =
  ({ teamData, onSuccess, onFailure }: CreateTeamActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: 'teams',
        query: teamData,
      });

      dispatch(CREATE_TEAM_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };

export const INVITE_USERS_SUCCESS = createAction('users/INVITE_USERS.SUCCESS');
type InviteUsersActionType = {
  usersEmails: string[] | string;
  role: string;
  currentLanguage?: string;
  onSuccess: (action: string) => void;
  onFailure: (error: string) => void;
};
export const inviteUsers =
  ({ usersEmails, role, currentLanguage, onSuccess, onFailure }: InviteUsersActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': currentLanguage,
        },
        endpoint: 'teams/invite',
        query: {
          emails: usersEmails,
          userRole: role,
        },
      });

      dispatch(INVITE_USERS_SUCCESS(data));
      onSuccess('reSubmitInvite');
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };

type ResendInvitationActionType = {
  userEmail: string;
  currentLanguage?: string;
  onSuccess: (action: string) => void;
  onFailure: (error: string) => void;
};
export const resendInvitation =
  ({ userEmail, currentLanguage, onSuccess, onFailure }: ResendInvitationActionType) =>
  async () => {
    try {
      await ApiService.apiCall({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': currentLanguage,
        },
        endpoint: 'teams/resend-invite',
        query: {
          userEmail: userEmail,
        },
      });

      onSuccess('reSubmitInvite');
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };

export const CANCEL_INVITE_TO_TEAM_SUCCESS = createAction('users/CANCEL_INVITE_TO_TEAM.SUCCESS');
type CancelInviteActionType = {
  userEmail: string;
  onSuccess: (action: string) => void;
  onFailure: (error: string) => void;
};
export const cancelInviteUsers =
  ({ userEmail, onSuccess, onFailure }: CancelInviteActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: 'teams/cancel-invite',
        query: {
          userEmail,
        },
      });

      dispatch(CANCEL_INVITE_TO_TEAM_SUCCESS(data));
      onSuccess('canselInvite');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

export const INVITE_DECISION_MAKER_SUCCESS = createAction('users/INVITE_DECISION_MAKER.SUCCESS');
type InviteDecisionMakerToTeamActionType = {
  usersData: DicisionMakerData[] | string;
  clientId: string;
  currentLanguage?: string;
  onSuccess: (action: string) => void;
  onFailure: (error: string) => void;
};

export const inviteDecisionMakerToTeam = ({
  usersData,
  clientId,
  currentLanguage,
  onSuccess,
  onFailure,
}: InviteDecisionMakerToTeamActionType) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: 'teams/invite-member',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': currentLanguage,
        },
        query: {
          data: usersData,
          clientId,
        },
      });

      dispatch(INVITE_DECISION_MAKER_SUCCESS(data));
      onSuccess('reSubmitInvite');
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };
};

export const REINVITE_DECISION_MAKER_SUCCESS = createAction('users/REINVITE_DECISION_MAKER.SUCCESS');
type ReInviteDecisionMakerToTeamActionType = {
  email: string;
  clientId: string;
  currentLanguage?: string;
  onSuccess: (action: string) => void;
  onFailure: (error: string) => void;
};
export const resendDecisionMakerInvitation = ({
  email,
  clientId,
  currentLanguage,
  onSuccess,
  onFailure,
}: ReInviteDecisionMakerToTeamActionType) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: 'teams/resend-invite-member',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': currentLanguage,
        },
        query: {
          email,
          clientId,
        },
      });

      dispatch(REINVITE_DECISION_MAKER_SUCCESS(data));
      onSuccess('reSubmitInvite');
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };
};

export const CANCEL_INVITE_DECISION_MAKER_SUCCESS = createAction('users/CANCEL_INVITE_DECISION_MAKER.SUCCESS');
type CancelInviteDecisionMakerToTeamActionType = {
  email: string;
  onSuccess: (action: string) => void;
  onFailure: (error: string) => void;
};
export const cancelDecisionMakerInvitationToTeam = ({
  email,
  onSuccess,
  onFailure,
}: CancelInviteDecisionMakerToTeamActionType) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: 'teams/cancel-invite-member',
        query: {
          email,
        },
      });

      dispatch(CANCEL_INVITE_DECISION_MAKER_SUCCESS(data));
      onSuccess('reSubmitInvite');
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };
};

export const UPDATE_TEAM_SUCCESS = createAction('users/UPDATE_TEAM.SUCCESS');
type UpdateTeamActionType = {
  name: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const updateTeam =
  ({ name, onSuccess, onFailure }: UpdateTeamActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: 'teams',
        query: { name: name },
      });

      dispatch(UPDATE_TEAM_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };

export const CHANGE_EMPLOYEE_ROLE_SUCCESS = createAction('teams/CHANGE_EMPLOYEE_ROLE.SUCCESS');
type ChangeEmployeeRoleActionType = {
  employeesData: {
    _id: string;
    role: string;
  }[];
  onSuccess: (action: string) => void;
  onFailure: (error: string) => void;
};
export const changeEmployeeRole =
  ({ employeesData, onSuccess, onFailure }: ChangeEmployeeRoleActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: 'teams/employees/roles',
        query: { employees: employeesData },
      });

      dispatch(CHANGE_EMPLOYEE_ROLE_SUCCESS(data));
      onSuccess('changeRole');
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };

export const CHANGE_EMPLOYEE_STATUS_SUCCESS = createAction('teams/CHANGE_EMPLOYEE_STATUS.SUCCESS');
type ChangeEmployeeStatusActionType = {
  employeeId: string;
  employeeStatus: string;
  onSuccess: (action: string) => void;
  onFailure: (error: string) => void;
};
export const changeEmployeeStatus =
  ({ employeeId, employeeStatus, onSuccess, onFailure }: ChangeEmployeeStatusActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PATCH',
        endpoint: `teams/employees/${employeeId}/status`,
      });

      dispatch(CHANGE_EMPLOYEE_STATUS_SUCCESS(data));
      onSuccess(employeeStatus === 'ACTIVE' ? 'archiveEmployee' : 'unarchiveEmployee');
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };

export const DELETE_TEAM_SUCCESS = createAction('users/DELETE_TEAM.SUCCESS');
type DeleteTeamActionType = {
  onSuccess: (action: string) => void;
  onFailure: (error: string) => void;
};
export const deleteTeam =
  ({ onSuccess, onFailure }: DeleteTeamActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'DELETE',
        endpoint: 'teams',
      });

      dispatch(DELETE_TEAM_SUCCESS(data));
      onSuccess('delete');
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      onFailure(errorMessage);
    }
  };

export const CLEAR_SELECTED_TEAM = createAction('teams/CLEAR_SELECTED_TEAM');
export const clearSelectedTeam = () => async (dispatch: AppDispatch) => {
  dispatch(CLEAR_SELECTED_TEAM());
};
