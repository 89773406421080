import { createReducer } from '@reduxjs/toolkit';

import { CHANGE_TEAM_SEARCH_FILTERS } from '../actions/filters/reportsFilters';
import { CHANGE_TEAM_FILTERS } from '../actions/filters/teamFilters';

const initialState = {
  teamFilters: null,
  teamSearchFilter: [],
};

const handlers = {
  [CHANGE_TEAM_FILTERS]: (prevState, { payload }) => {
    return {
      ...prevState,
      teamFilters: payload,
    };
  },

  [CHANGE_TEAM_SEARCH_FILTERS]: (prevState, { payload }) => {
    return {
      ...prevState,
      teamSearchFilter: payload,
    };
  },
};

export default createReducer(initialState, handlers);
