import { getTeamById } from 'redux/actions/teams';
import { store } from '../../../index';

export function* onUpdateDicisionMakers() {
  const { dispatch } = store;

  dispatch(getTeamById({}));

  yield true;
}
