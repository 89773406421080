import { Button, Divider, Modal, Typography } from '@mui/material';
import { employeeRolesLabels, employeesRoles } from 'assets/roles';
import MemberEmailsInput from 'components/teams-section/members-emails';
import { useFormik } from 'formik';
import { useNotification } from 'hooks/useNotification';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { inviteUsers } from 'redux/actions/teams';
import CustomInput from 'shared/input';
import ToggleButtonsGroup from 'shared/toggle-buttons-group';

import Notification from '../../notification';

import { getValidationSchema, InitialValues, initialValues } from './config';
import useStyles from './style';

type InviteUsersModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isBtnDisabled: boolean;
  setIsBtnDisabled: Dispatch<SetStateAction<boolean>>;
};

const InviteUsersModal: React.FC<InviteUsersModalProps> = ({ isOpen, setIsOpen, isBtnDisabled, setIsBtnDisabled }) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [notificationMessage, setNotificationMessage] = useState<{
    success: string;
    error: string;
  }>({
    success: '',
    error: '',
  });
  const [showNotification, toggleNotification] = useNotification();
  const [usersEmails, setUsersEmails] = useState<string[]>([]);
  const [emptyEmailsError, setEmptyEmailsError] = useState<string | false>(false);

  const dispatch = useDispatch();

  const onSuccessSubmit = () => {
    setIsOpen(false);
    setNotificationMessage({ success: t('membersInvitedSuccess'), error: '' });
    toggleNotification();
    setUsersEmails([]);
    setIsBtnDisabled(false);
  };

  const onFailure = (error: string) => {
    setNotificationMessage({ success: '', error });
    toggleNotification();
    setIsBtnDisabled(false);
  };

  const inviteUsersSubmitHandler = (values: InitialValues) => {
    if (!usersEmails.length) {
      formik.setFieldError('memberEmail', t('emptyEmailsError') as string);
      formik.setTouched({ memberEmail: true }, true);
      setEmptyEmailsError(t('emptyEmailsError') as string);
      return;
    }

    setIsBtnDisabled(true);

    dispatch(
      inviteUsers({
        usersEmails,
        role: values.memberRole,
        currentLanguage,
        onSuccess: onSuccessSubmit,
        onFailure,
      }),
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationSchema(usersEmails),
    onSubmit: inviteUsersSubmitHandler,
    validateOnMount: true,
  });

  const closeModal = () => {
    setIsOpen(false);
    formik.resetForm();
    setUsersEmails([]);
  };

  const field = (params: {
    name: string;
    label: string;
    type: string;
    helperText?: string;
    helperTextStyle?: string;
    formControlStyle?: string;
    disabled?: boolean;
    keyDownPressHandle?: (event: React.KeyboardEvent) => void;
    endAdornment?: JSX.Element;
    isReset?: boolean;
    setIsReset?: Dispatch<SetStateAction<boolean>>;
  }) => {
    const {
      name,
      label,
      type,
      helperText,
      helperTextStyle,
      formControlStyle,
      disabled,
      keyDownPressHandle,
      endAdornment,
      isReset,
      setIsReset,
    } = params;

    return (
      <CustomInput
        defaultValue={formik.values[name as keyof Omit<InitialValues, string>]}
        onChange={(value: string) => {
          formik.setFieldValue(name, value);
          setEmptyEmailsError(false);
        }}
        onBlur={() => {
          formik.errors[name as keyof InitialValues] && formik.setFieldTouched(name, true, true);
        }}
        onKeyDownPress={keyDownPressHandle}
        inputType={type}
        label={label}
        name={name}
        variant='outlined'
        margin='none'
        showErrorText={
          !!(
            formik.touched[name as keyof InitialValues] &&
            (formik.errors[name as keyof Omit<InitialValues, string>] || !!emptyEmailsError)
          )
        }
        formikError={
          (formik.touched[name as keyof InitialValues] && formik.errors[name as keyof Omit<InitialValues, string>]) ||
          emptyEmailsError
        }
        helperText={
          helperText
            ? helperText
            : formik.touched[name as keyof InitialValues] &&
              (formik.errors[name as keyof Omit<InitialValues, string>] || emptyEmailsError)
        }
        helperTextStyle={helperTextStyle}
        formControlStyle={formControlStyle}
        disabled={disabled}
        endAdornment={endAdornment}
        isReset={isReset}
        setIsReset={setIsReset}
      />
    );
  };

  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') event.preventDefault();
  };

  return (
    <div>
      <Modal open={isOpen} onClose={closeModal} className='modal'>
        <div className={`modal-container`}>
          <form noValidate onSubmit={formik.handleSubmit} onKeyDown={handleEnterPress}>
            <Typography className={classes.modalTitle}>{t('membersEmailsFieldTitle')}</Typography>
            <MemberEmailsInput
              createInput={field}
              membersEmails={usersEmails}
              setMembersEmails={setUsersEmails}
              formik={formik}
              isInviteModal
            />
            <Divider variant='middle' className={`${classes.divider} ${classes.dividerMobile}`} />
            <Typography
              className={classes.userRoleTitle}
              sx={
                !!(formik.touched.memberRole && formik.errors.memberRole) ? { marginTop: '16px' } : { marginTop: '5px' }
              }
            >
              {t('userRoleSelectText')}
            </Typography>
            <ToggleButtonsGroup
              ariaLabel='employees-roles'
              buttonValues={employeesRoles}
              buttonLabels={employeeRolesLabels()}
              size='small'
              formikValue={formik.values.memberRole}
              onSelect={(memberRole) => {
                formik.setFieldValue('memberRole', memberRole);
              }}
              isError={!!(formik.touched.memberRole && formik.errors.memberRole)}
              error={formik.touched.memberRole ? formik.errors.memberRole : ''}
              buttonsGroupProps={classes.rolesContainer}
              buttonProps={classes.roleButton}
            />
            <Divider variant='middle' className={`${classes.divider} ${classes.dividerDesktop}`} />
            <div className={classes.formButtons}>
              <Button
                variant='contained'
                color='secondary'
                type='reset'
                className={classes.button}
                onClick={closeModal}
              >
                {t('confirmCloseVacancy')}
              </Button>
              <Button variant='contained' type='submit' disabled={isBtnDisabled} className={classes.button}>
                {t('sendInviteBtnText')}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Notification
        success={notificationMessage.success}
        error={notificationMessage.error}
        isOpen={showNotification}
        onClose={toggleNotification}
      />
    </div>
  );
};

export default InviteUsersModal;
