import i18next from 'i18next';

export const roles = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const employeesRoles = ['USER', 'COMPANY_ADMIN'];

export const employeeRolesLabels = () => ({
  COMPANY_ADMIN: i18next.t('teamAdminRole'),
  USER: i18next.t('userRole'),
});

export type EmployeeRole = {
  COMPANY_ADMIN: string;
  USER: string;
};

export type TeamRole = {
  COMPANY_OWNER: string;
  COMPANY_ADMIN: string;
  USER: string;
};

export const teamRoles = ['COMPANY_OWNER', 'COMPANY_ADMIN', 'USER'];

export enum employeeRolesEnum {
  COMPANY_OWNER = 'COMPANY_OWNER',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  USER = 'USER',
}

export const teamRolesLabels = (): TeamRole => ({
  COMPANY_OWNER: i18next.t('teamOwnerRole'),
  COMPANY_ADMIN: i18next.t('teamAdminRole'),
  USER: i18next.t('userRole'),
});

export const employeeStatus = ['ACTIVE', 'PENDING', 'ARCHIVED'];
export enum decisionMakersStatus {
  'ACTIVE' = 'ACTIVE',
  'PENDING' = 'PENDING',
  'ARCHIVED' = 'ARCHIVED',
}

export type EmployeeStatus = {
  ACTIVE: string;
  PENDING: string;
  ARCHIVED: string;
};

export const employeeStatusLabels = (): EmployeeStatus => ({
  ACTIVE: i18next.t('activeEmployeeStatus'),
  PENDING: i18next.t('pendingEmployeeStatus'),
  ARCHIVED: i18next.t('archivedEmployeeStatus'),
});
