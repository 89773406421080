import { AppDispatch, createAction } from '@reduxjs/toolkit';
import { InititalValues } from 'components/candidates-section/candidate-form/config';
import { IVacanciesByCandidateResponse } from 'types/api/vacancies';
import { IVacancyByCandidateFilters } from 'types/filters/vacancies';
import { getErrorMessage } from '../../helpers/getErrorMessage';
import { IGetAllCandidates } from '../../types/api/candidates';
import { ICandidateActions } from '../../types/filters/candidates';
import { ApiService } from '../middleware/api';

type FetchAllCandidatesActionType = {
  page?: number | null;
  offset?: number | null;
  filters: ICandidateActions;
  onFailure: (error: string) => void;
  onSuccess?: (response: IGetAllCandidates) => void;
  candidateName?: string;
};
export const GET_ALL_CANDIDATES_PENDING = createAction('candidates/GET_ALL_CANDIDATES.PENDING');
export const GET_ALL_CANDIDATES_SUCCESS = createAction('candidates/GET_ALL_CANDIDATES.SUCCESS');
export const GET_ALL_CANDIDATES_FAILURE = createAction('candidates/GET_ALL_CANDIDATES.FAILURE');
export const getAllCandidates =
  ({ filters, page, offset, onFailure, onSuccess }: FetchAllCandidatesActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(GET_ALL_CANDIDATES_PENDING());
      const sortedBy = filters?.column ? { [filters.column.name]: filters.column.direction === 'down' ? -1 : 1 } : null;

      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `candidates`,
        qsParams: {
          page,
          offset,
          candidateName: filters?.searchByName || null,

          tag: filters?.filter?.candidateTag || null,
          speciality: filters?.filter?.speciality || null,
          grade: filters?.filter?.grade || null,
          client: filters?.filter?.client || null,
          vacancy: filters?.filter?.vacancy || null,
          vacancyStatus: filters?.filter?.vacancyStatus || null,
          availability: filters?.filter?.availability || null,

          sortedBy: sortedBy ? JSON.stringify(sortedBy) : null,
        },
      });

      dispatch(GET_ALL_CANDIDATES_SUCCESS(data));
      onSuccess?.(data);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);

      dispatch(GET_ALL_CANDIDATES_FAILURE());
    }
  };

type GetCandidateByIdActionType = {
  candidateId: string;
  onFailure?: (error: string) => void;
};
export const GET_CANDIDATE_BY_ID_SUCCESS = createAction('candidates/GET_CANDIDATE_BY_ID.SUCCESS');
export const getCandidateById =
  ({ candidateId, onFailure }: GetCandidateByIdActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `candidates/${candidateId}`,
      });

      dispatch(GET_CANDIDATE_BY_ID_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
    }
  };

type CreateCandidateActionType = {
  candidateData: any;
  candidateDocuments: File[];
  onSuccess: (values: InititalValues) => void;
  onFailure: (error: string) => void;
};
export const CREATE_CANDIDATE_SUCCESS = createAction('candidates/CREATE_CANDIDATE.SUCCESS');
export const createCandidate =
  ({ candidateData, candidateDocuments, onSuccess, onFailure }: CreateCandidateActionType) =>
  async (dispatch: AppDispatch) => {
    const formData = new FormData();

    for (let i = 0; i < candidateDocuments.length; i++) {
      formData.append('documents', candidateDocuments[i]);
    }

    const keys = Object.keys(candidateData);
    Object.values(candidateData).forEach((value: any, index) => formData.append(keys[index], value));

    try {
      const { data } = await ApiService.apiCall({
        method: 'POST',
        endpoint: 'candidates',
        query: formData,
      });

      dispatch(CREATE_CANDIDATE_SUCCESS(data));
      onSuccess(candidateData);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

type UpdateCandidateActionType = {
  candidateId: string;
  candidateData: any;
  candidateDocuments: File[];
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const UPDATE_CANDIDATE_SUCCESS = createAction('candidates/UPDATE_CANDIDATE.SUCCESS');
export const updateCandidate =
  ({ candidateId, candidateData, candidateDocuments, onSuccess, onFailure }: UpdateCandidateActionType) =>
  async (dispatch: AppDispatch) => {
    const formData = new FormData();

    for (let i = 0; i < candidateDocuments.length; i++) {
      formData.append('documents', candidateDocuments[i]);
    }

    const keys = Object.keys(candidateData);
    Object.values(candidateData).forEach((value: any, index) => formData.append(keys[index], value));

    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `candidates/${candidateId}`,
        query: formData,
      });

      dispatch(UPDATE_CANDIDATE_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

type ChangeCandidateStatusActionType = {
  candidateId: string;
  status: string;
  vacancyId: string;
  onSuccess?: () => void;
  onFailure?: (error: string) => void;
};
export const CHANGE_CANDIDATE_STATUS_SUCCESS = createAction('candidates/CHANGE_CANDIDATE_STATUS.SUCCESS');
export const changeCandidateStatus =
  ({ candidateId, status, vacancyId, onSuccess, onFailure }: ChangeCandidateStatusActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PATCH',
        endpoint: `candidates/status/${candidateId}`,
        query: { status, vacancyId },
      });
      dispatch(CHANGE_CANDIDATE_STATUS_SUCCESS(data));
      onSuccess?.();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure?.(errorMessage);
    }
  };

type HireCandidateActionType = {
  candidateId: string;
  vacancyId: string;
  description: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const HIRE_CANDIDATE_SUCCESS = createAction('candidates/HIRE_CANDIDATE.SUCCESS');
export const hireCandidate =
  ({ candidateId, vacancyId, description, onSuccess, onFailure }: HireCandidateActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `candidates/hire/${candidateId}`,
        query: { vacancyId, description },
      });

      dispatch(HIRE_CANDIDATE_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

type RejectCandidateActionType = {
  candidateId: string;
  vacancyId: string;
  description: string;
  refusalReason: string;
  onSuccess: () => void;
  onFailure: (error: string) => void;
};
export const REJECT_CANDIDATE_SUCCESS = createAction('candidates/REJECT_CANDIDATE.SUCCESS');
export const rejectCandidate =
  ({ candidateId, vacancyId, description, refusalReason, onSuccess, onFailure }: RejectCandidateActionType) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await ApiService.apiCall({
        method: 'PUT',
        endpoint: `candidates/reject/${candidateId}`,
        query: { vacancyId, refusalReason, description },
      });

      dispatch(REJECT_CANDIDATE_SUCCESS(data));
      onSuccess();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      onFailure(errorMessage);
    }
  };

export const CLEAR_SELECTED_CANDIDATE = createAction('candidates/CLEAR_SELECTED_CANDIDATE');
export const clearSelectedCandidate = () => (dispatch: AppDispatch) => {
  dispatch(CLEAR_SELECTED_CANDIDATE());
};

export const GET_ALL_VACANCIES_BY_CANDIDATES_SUCCESS = createAction(
  'candidates/GET_ALL_VACANCIES_OF_CANDIDATES.SUCCESS',
);

export const GET_ALL_VACANCIES_BY_CANDIDATES_PENDING = createAction(
  'candidates/GET_ALL_VACANCIES_BY_CANDIDATES.SUCCESS',
);
export const GET_ALL_VACANCIES_BY_CANDIDATES_FAILURE = createAction(
  'candidates/GET_ALL_VACANCIES_BY_CANDIDATES_FAILURE.FAILURE',
);

type GetVacanciesByCandidateType = {
  candidateId: string;
  filters?: IVacancyByCandidateFilters;
  page?: string;
  offset?: string;
  onFailure?: (error: string) => void;
  onSuccess?: (response: IVacanciesByCandidateResponse) => void;
};

export const getAllVacanciesByCandidate =
  ({ filters, page, offset, candidateId, onFailure, onSuccess }: GetVacanciesByCandidateType) =>
  async (dispatch: AppDispatch) => {
    dispatch(GET_ALL_VACANCIES_BY_CANDIDATES_PENDING());
    try {
      const { data } = await ApiService.apiCall({
        method: 'GET',
        endpoint: `candidates/${candidateId}/vacancies`,
        qsParams: {
          page: page ?? null,
          offset: offset ?? null,
          sortDirection: filters?.sortDirection ?? null,
          sortByField: filters?.sortByField ?? null,
        },
      });
      onSuccess?.(data);
      dispatch(GET_ALL_VACANCIES_BY_CANDIDATES_SUCCESS(data));
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      dispatch(GET_ALL_VACANCIES_BY_CANDIDATES_FAILURE());
      onFailure?.(errorMessage);
    }
  };

export const SET_VACANCIES_BY_CANDIDATE_PAGE = createAction<number>('candidates/SET_VACANCIES_BY_CANDIDATE_PAGE');

export const changeCurrentVacanciesByCandidatePage = (currentPage: number) => (dispatch: AppDispatch) => {
  dispatch(SET_VACANCIES_BY_CANDIDATE_PAGE(currentPage));
};

export const CLEAR_CANDIDATES = createAction('candidates/CLEAR_CANDIDATES');
export const clearCandidates = () => (dispatch: AppDispatch) => {
  dispatch(CLEAR_CANDIDATES());
};
